<template>
  <b-card
      class="salesorder-edit-wrapper"
  >
    <validation-observer ref="simpleRules">
      <!-- form -->
      <b-form id="salesorderForm" class="edit-form mt-2">
        <b-row>
          <!--          仓库人员隐藏-->
          <b-row>
            <b-col md="12">
              <div class="inner-card-title">基本信息</div>
            </b-col>

            <b-col :md="oneSwitch == '1'?6:4">
              <b-card header="基本信息">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="销售编号"
                        label-for="order_no"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="order_no"
                          size="sm"
                          v-model="salesorder.order_no"
                          readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="user.role_id!=9&&user.role_id!=10">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="业务类型"
                        label-for="type_id"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <v-select
                          id="type_id"
                          :options="getCodeOptions('business_type')"
                          :clearable="true"
                          v-model="typeId"
                          @input="changeSelect('type_id',$event)"
                          class="select-size-sm"
                          placeholder="请选择业务类型"
                          disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="user.role_id!=9&&user.role_id!=10">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="贸易方式"
                        label-for="trade_type"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <v-select
                          id="trade_type"
                          :options="getCodeOptions('trade_type')"
                          :clearable="true"
                          v-model="tradeType"
                          @input="changeSelect('trade_type',$event)"
                          class="select-size-sm"
                          placeholder="请选择贸易方式"
                          disabled
                      />
                    </b-form-group>
                  </b-col>
<!--                  <b-col md="12">-->
<!--                    <b-form-group-->
<!--                        label-cols="3"-->
<!--                        label-cols-lg="3"-->
<!--                        label="主体名称"-->
<!--                        label-for="company_name"-->
<!--                        label-size="sm"-->
<!--                        class="mb-1 required"-->
<!--                    >-->
<!--                      <v-select-->
<!--                          id="company_name"-->
<!--                          :options="companyNameArray"-->
<!--                          :clearable="true"-->
<!--                          class="select-size-sm"-->
<!--                          v-model="companyName"-->
<!--                          @input="setCompanyName"-->
<!--                          placeholder="请选择主体名称"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->

                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="团队名称"
                        label-for="label"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <v-select
                          id="store_name"
                          :options="storeArray.length===0?[]:storeArray"
                          :clearable="true"
                          class="select-size-sm"
                          v-model="storeName"
                          @input="setStore($event)"
                          placeholder="请选择团队"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="业务员"
                        label-for="sales_name"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <v-select
                          id="sales_name"
                          :options="salesArray.length===0?[]:salesArray"
                          :clearable="true"
                          class="select-size-sm"
                          v-model="salesName"
                          @input="setSales($event)"
                          placeholder="请选择业务员"
                      />
<!--                      <b-form-input-->
<!--                          id="sales_name"-->
<!--                          size="sm"-->
<!--                          v-model="salesorder.sales_name"-->
<!--                          @input="salesName"-->
<!--                          v-b-modal.modal-select-user-->
<!--                          placeholder="点击搜索姓名"-->
<!--                          readonly-->
<!--                      />-->

                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="业务员电话"
                        label-for="sales_mobile"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="sales_mobile"
                          size="sm"
                          v-model="salesorder.sales_mobile"
                          readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col :md="oneSwitch == '1'?6:4">
              <b-card header="渠道方/店铺信息">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="终端客户"
                        label-for="label"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <v-select
                          id="channel_name"
                          :options="channelArray.length===0?[]:channelArray"
                          :clearable="true"
                          class="select-size-sm"
                          v-model="channelName"
                          :dropdown-should-open="openSelect"
                          @input="setChannelName"
                          placeholder="选择终端客户(至少输入2个字)，可输入拼音首字母"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="客户联系人"
                        label-for="channel_contact_name"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="channel_contact_name"
                          size="sm"
                          v-model="salesorder.channel_contact_name"
                          readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="客户联系电话"
                        label-for="channel_contact_mobile"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="channel_contact_mobile"
                          size="sm"
                          v-model="salesorder.channel_contact_mobile"
                          readonly
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="仓库名称"
                        label-for="label"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <v-select
                          id="sales_warehouse"
                          :options="salesWarehouseArray.length===0?[]:salesWarehouseArray"
                          :clearable="true"
                          class="select-size-sm"
                          v-model="salesWarehouse"
                          @input="changeSelect('sales_warehouse',$event)"
                          placeholder="请选择仓库"
                          :disabled="readonly!==0"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="销售日期"
                        label-for="label"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <flat-pickr
                          v-model="salesorder.sales_time"
                          class="form-control"
                          placeholder="请选择销售日期"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="配送日期"
                        label-for="label"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <flat-pickr
                          v-model="salesorder.delivery_time"
                          class="form-control"
                          placeholder="请选择配送日期"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="抬头"
                        label-for="label"
                        label-size="sm"
                        class="mb-1"
                    >
                      <v-select
                          id="bill_head"
                          :options="billHeadArray.length===0?[]:billHeadArray"
                          :clearable="true"
                          class="select-size-sm"
                          v-model="billHead"
                          @input="changeSelect('bill_head',$event)"
                          placeholder="请选择抬头"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col md="4" v-if="user.role_id!=9&&user.role_id!=10&&oneSwitch != '1'">
              <b-card header="合同信息">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="渠道合同编号"
                        label-for="channel_order_no"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="channel_order_no"
                          size="sm"
                          v-model="salesorder.channel_order_no"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="合同编号"
                        label-for="contract_no"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="contract_no"
                          size="sm"
                          v-model="salesorder.contract_no"
                          @input="contractNo"
                          v-b-modal.modal-select-contract
                          placeholder="点击搜索合同"
                      />

                    </b-form-group>

                  </b-col>
                  <b-col md="12">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="合同类型"
                        label-for="label"
                        label-size="sm"
                        class="mb-1 required"
                    >
                      <v-select
                          id="contract_type"
                          :options="getCodeOptions('agreement_specific_type_XS')"
                          :clearable="true"
                          v-model="contractType"
                          @input="changeSelect('contract_type',$event)"
                          class="select-size-sm"
                          placeholder="请选择合同类型"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

          </b-row>

          <b-col md="12">
            <div class="inner-card-title">销售单明细</div>
          </b-col>

          <b-col md="12">
            <sales-order-item-list
                :order_id="salesorder.order_id"
                :companyId="salesorder.company_id"
                :warehouseId="salesorder.sales_warehouse"
                :channelId="salesorder.channel_id"
                :readonly="readonly"
                :purchaseTeamUserId="salesorder.sales_id"
                :purchaseTeamStoreId="salesorder.store_id"
                v-if="itemListShowFlag"
                ref="itemList"
                v-on:table="fromChildren">
            </sales-order-item-list>
          </b-col>


          <b-col md="12" class="mt-2">
            <div class="inner-card-title">其他信息</div>
          </b-col>
          <b-col md="12">
            <b-card header="其他信息">
              <b-row>
                <b-col md="4" v-if="user.role_id!=9&&user.role_id!=10">
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="发票类型"
                      label-for="invoice_type"
                      label-size="sm"
                      class="mb-1 required"
                  >
                    <v-select
                        id="invoice_type"
                        :options="getCodeOptions('invoice_type')"
                        :clearable="true"
                        v-model="invoiceType"
                        @input="changeSelect('invoice_type',$event)"
                        class="select-size-sm"
                        placeholder="请选择发票类型"
                    />
                  </b-form-group>
                </b-col>
<!--                <b-col md="4" v-if="user.role_id!=9&&user.role_id!=10">-->
<!--                  <b-form-group-->
<!--                      label-cols="3"-->
<!--                      label-cols-lg="3"-->
<!--                      label="是否预付款"-->
<!--                      label-for="need_advance"-->
<!--                      label-size="sm"-->
<!--                      class="mb-1"-->
<!--                  >-->
<!--                    <b-form-checkbox-->
<!--                        name="check-button"-->
<!--                        switch-->
<!--                        inline-->
<!--                        id="need_advance"-->
<!--                        v-model="salesorder.need_advance"-->
<!--                        value="1"-->
<!--                        unchecked-value="0"-->
<!--                    ></b-form-checkbox>-->
<!--                  </b-form-group>-->
<!--                </b-col>-->
                <b-col md="12">
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="1"
                      label="备注"
                      label-for="remark"
                      label-size="sm"
                      class="mb-1"
                  >
                    <b-form-textarea
                        id="remark"
                        size="sm"
                        v-model="salesorder.remark"
                        rows="3"
                        max-rows="6"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                      label-cols="1"
                      label-cols-lg="1"
                      label="附件上传"
                      label-for="attachments"
                      label-size="sm"
                      :class="'mb-1'"
                  >
                    <attachment-upload v-if="salesorder.loaded" :theme="'files'"
                                       :attachment_id="'attachments'"
                                       :id="salesorder.attachments"
                                       :object_type="'sales_order'"
                                       :object_id="salesorder.order_id"
                                       @change="onUploaded"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="success"
                class="mr-1"
                @click="save"
                v-if="readonly===0"
            >
              保存
            </b-button>
            <b-button
                variant="outline-secondary"
                class="mr-1"
                @click="cancel"
            >
              返回
            </b-button>

            <b-button
                variant="primary"
                class="mr-1"
                @click="saveAndSubmit"
                v-if="readonly===0"
            >
              提交
            </b-button>
            <b-button
                variant="primary"
                class="mr-1"
                @click="saveAndSubmitAndPrint"
                v-if="readonly===0"
            >
              提交并打印
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <b-modal
          id="modal-select-user"
          ok-only
          ok-title="确认"
          @ok="onSelectUser"
          cancel-title="取消"
          centered
          size="lg"
          title="选择姓名"
      >
        <user-select
            ref="myUserSelect" v-on:table="fromChildrenDB($event,'modal-select-user')"
        >
        </user-select>
      </b-modal>

      <b-modal
          id="modal-select-contract"
          ok-only
          ok-title="确认"
          @ok="onSelectContract"
          cancel-title="取消"
          centered
          size="lg"
          title="选择合同"
      >
        <contract-select
            ref="contractSelect" :type="2" :status="2" v-on:table="fromChildrenDB($event,'modal-select-contract')"
        >
        </contract-select>
      </b-modal>
      <!--/ form -->
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import salesorderStore from '@/views/apps/offlinesalesorder/salesorderStore'
import salesorderitemStore from "@/views/apps/offlinesalesorderitem/salesorderitemStore";
import storeStore from "@/views/apps/store/storeStore";
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  second,
  lodopWatermark, isEmpty
} from '@core/utils/filter'
import axios from "@/libs/axios";
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import ContractSelect from "@/views/apps/contract/ContractSelect";
import SalesOrderItemList from "@/views/apps/offlinesalesorderitem/SalesOrderItemList";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {getUserData} from "@/auth/utils";
import storesalesStore from "@/views/apps/storesales/storesalesStore";
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import contactStore from "@/views/apps/contact/contactStore";
import companyStore from "@/views/apps/company/companyStore";
import warehouseheadStore from "@/views/apps/warehousehead/warehouseheadStore";
import {getLodop} from "@/libs/LodopFuncs";
import lodopStore from "@/views/apps/lodop/lodopStore";

export default {
  components: {
    SalesOrderItemList,
    ContractSelect,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    UserSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      salesorder: ref({}),
      typeId: {},
      oneSwitch: null,
      channelNameArray: [],
      channelName: "",
      a: [],
      tradeType: {},
      required,
      email,
      companyNameArray: [],
      companyName: {},
      items: [],
      salesName: {},
      contractNo: {},
      contractType: {},
      invoiceType: {},
      paymentMethod: {},
      deliveryType: {},
      status: {},
      state: {},
      settlementMethod: {},
      itemListShowFlag: false,
      salesOrderItem: [],
      storeArray: [],
      channelArray: [],
      storeName: "",
      readonly: 0,
      user: {},
      salesWarehouseArray: [],
      salesWarehouse: "",
      salesArray:[],
      billHeadArray:[],
      billHead:"",
    }
  },
  methods: {
    setCompanyName() {
      if (this.companyName != null) {
        this.salesorder.company_name = this.companyName.label
        this.salesorder.company_id = this.companyName.company_id
        this.itemListShowFlag = false
        this.$nextTick(() => {
          this.itemListShowFlag = true
        })
      } else {
        this.salesorder.company_name = null
        this.salesorder.company_id = null
      }
    },
  },
  props: {
    order_id: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('offlinesalesorder')) store.registerModule('offlinesalesorder', salesorderStore)
    if (!store.hasModule('offlinesalesorderitem')) store.registerModule('offlinesalesorderitem', salesorderitemStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('storesales')) store.registerModule('storesales', storesalesStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('contact')) store.registerModule('contact', contactStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('warehousehead')) store.registerModule('warehousehead', warehouseheadStore)
    if (!store.hasModule('lodop')) store.registerModule('lodop', lodopStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('offlinesalesorder')) store.unregisterModule('offlinesalesorder')
      if (store.hasModule('offlinesalesorderitem')) store.unregisterModule('offlinesalesorderitem')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('storesales')) store.unregisterModule('storesales')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('contact')) store.unregisterModule('contact')
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('warehousehead')) store.unregisterModule('warehousehead')
      if (store.hasModule('lodop')) store.unregisterModule('lodop')
    })

    const edit = function () {
      store.dispatch('offlinesalesorder/edit', {id: this.id}).then(res => {
        if (res.data.code == 1) {
          toast.error(res.data.data)
          this.$router.push({name: 'apps-offlinesalesorder-list'})
        }
        this.salesorder = res.data.data
        this.itemListShowFlag = true
        this.typeId = getCode('business_type', this.salesorder.type_id)
        this.tradeType = getCode('trade_type', this.salesorder.trade_type)
        getCompanyByCompanyTypeIsTwo(this.companyNameArray)
        this.companyName = this.salesorder.company_name
        this.contractType = getCode("agreement_specific_type_XS", this.salesorder.contract_type)
        this.invoiceType = getCode("invoice_type", this.salesorder.invoice_type)
        this.paymentMethod = getCode("agreement_pay_method", this.salesorder.payment_method)
        this.deliveryType = getCode("delivery_method_sale", this.salesorder.delivery_type)
        this.settlementMethod = getCode("settlement_method", this.salesorder.settlement_method)
        this.status = getCode("contract_status", this.salesorder.status)
        this.state = getCode("state", this.salesorder.state)
        this.storeName = this.salesorder.store_name
        this.channelName = this.salesorder.channel_name
        this.salesorder.sales_time = toTime(this.salesorder.sales_time)
        this.salesorder.delivery_time = toTime(this.salesorder.delivery_time)
        this.salesWarehouse = getCodeLabel("warehouse", this.salesorder.sales_warehouse)
        this.salesName = getCodeLabel("user", this.salesorder.sales_id)
        this.billHead = getCodeLabel("warehouse_head", this.salesorder.bill_head)
        this.getStoreByUser(this)
      })
    }

    const view = function () {
      store.dispatch('offlinesalesorder/view', {id: this.id}).then(res => {
        this.salesorder = res.data.data
      })
    }

    const cancel = function () {
      this.$router.push({name: 'apps-offlinesalesorder-list'})
    }

    const save = function () {
      if (this.salesorder.type_id === undefined || this.salesorder.type_id === "") {
        toast.error("请选择 业务类型!")
        return false
      }
      if (this.salesorder.channel_name === undefined || this.salesorder.channel_name === ""||this.salesorder.channel_name == null) {
        toast.error("请选择 渠道方!")
        return false
      }
      if (this.salesorder.trade_type === undefined || this.salesorder.trade_type === "") {
        toast.error("请选择  贸易方式!")
        return false
      }
      if (this.salesorder.company_name === undefined || this.salesorder.company_name === "") {
        toast.error("请选择  主体名称!")
        return false
      }
      if (this.salesorder.sales_id === undefined || this.salesorder.sales_id === ""||this.salesorder.sales_id == null) {
        toast.error("请选择  业务员!")
        return false
      }
      if (this.salesorder.contract_type === undefined || this.salesorder.contract_type === "") {
        toast.error("请选择  合同类型!")
        return false
      }
      if (this.salesorder.invoice_type === undefined || this.salesorder.invoice_type === "") {
        toast.error("请选择  发票类型!")
        return false
      }
      /*if (this.salesorder.settlement_method === undefined || this.salesorder.settlement_method === "") {
        toast.error("请选择  结算方式!")
        return false
      }
      if (this.salesorder.delivery_type === undefined || this.salesorder.delivery_type === "") {
        toast.error("请选择  物流方式!")
        return false
      }
      if (this.salesorder.payment_method === undefined || this.salesorder.payment_method === "") {
        toast.error("请选择  付款方式!")
        return false
      }*/
      if (this.salesorder.store_id === undefined || this.salesorder.store_id === ""||this.salesorder.store_id == null) {
        toast.error("请选择  店铺!")
        return false
      }
      if (this.salesorder.sales_warehouse === undefined || this.salesorder.sales_warehouse === ""||this.salesorder.sales_warehouse == null) {
        toast.error("请选择  仓库!")
        return false
      }
      if (this.salesorder.sales_time === undefined || this.salesorder.sales_time === "") {
        toast.error("请填写  售卖时间!")
        return false
      }
      if (this.salesorder.delivery_time === undefined || this.salesorder.delivery_time === "") {
        toast.error("请填写  配送日期!")
        return false
      }

      if (second(this.salesorder.sales_time)>second(this.salesorder.delivery_time)){
        toast.error("售卖日期必须小于等于配送日期！")
        return false
      }



      let itemData = this.$refs.itemList.tableArray

      for (let i = 0; i < itemData.length; i++) {
        if (itemData[i].product_cost_tax==0&&itemData[i].is_gift==0){
          toast.error(itemData[i].name+"售价为0，请勾选为赠品!")
          return false
        }
        if (itemData[i].qty ==undefined||itemData[i].qty =='') {
          toast.error("销售数量必填!")
          return false
        }
        if (itemData[i].product_cost_tax < 0) {
          toast.error("含税售价必须大于等于0!")
          return false
        }
        if (itemData[i].qty <= 0&&itemData[i].ext.categoryId!=352) {
          toast.error("售卖数量必须大于0!")
          return false
        }
      }

      this.salesorder.sales_time = second(this.salesorder.sales_time)
      this.salesorder.delivery_time = second(this.salesorder.delivery_time)

      for (let i = 0; i < this.salesOrderItem.length; i++) {
        saveSalesOrderItem(this.salesOrderItem[i])
      }

      store.dispatch('offlinesalesorder/save', this.salesorder).then(res => {
        if (res.data.code === 0) {
          toast.success('数据已保存!')
        } else {
          toast.error(res.data.data)
        }
      })

      this.salesorder.sales_time = toTime(this.salesorder.sales_time)
      this.salesorder.delivery_time = toTime(this.salesorder.delivery_time)
    }

    const saveAndSubmit = function () {
      if (this.salesorder.type_id === undefined || this.salesorder.type_id === "") {
        toast.error("请选择 业务类型!")
        return false
      }
      if (this.salesorder.channel_name === undefined || this.salesorder.channel_name === ""||this.salesorder.channel_name == null) {
        toast.error("请选择 渠道方!")
        return false
      }
      if (this.salesorder.trade_type === undefined || this.salesorder.trade_type === "") {
        toast.error("请选择  贸易方式!")
        return false
      }
      if (this.salesorder.company_name === undefined || this.salesorder.company_name === "") {
        toast.error("请选择  主体名称!")
        return false
      }
      if (this.salesorder.sales_id === undefined || this.salesorder.sales_id === ""||this.salesorder.sales_id == null) {
        toast.error("请选择  业务员!")
        return false
      }
      if (this.salesorder.contract_type === undefined || this.salesorder.contract_type === "") {
        toast.error("请选择  合同类型!")
        return false
      }
      if (this.salesorder.invoice_type === undefined || this.salesorder.invoice_type === "") {
        toast.error("请选择  发票类型!")
        return false
      }
      /*if (this.salesorder.settlement_method === undefined || this.salesorder.settlement_method === "") {
        toast.error("请选择  结算方式!")
        return false
      }
      if (this.salesorder.delivery_type === undefined || this.salesorder.delivery_type === "") {
        toast.error("请选择  物流方式!")
        return false
      }
      if (this.salesorder.payment_method === undefined || this.salesorder.payment_method === "") {
        toast.error("请选择  付款方式!")
        return false
      }*/
      if (this.salesorder.store_id === undefined || this.salesorder.store_id === ""||this.salesorder.store_id == null) {
        toast.error("请选择  店铺!")
        return false
      }
      if (this.salesorder.sales_warehouse === undefined || this.salesorder.sales_warehouse === ""||this.salesorder.sales_warehouse == null) {
        toast.error("请选择  仓库!")
        return false
      }
      if (this.salesorder.sales_time === undefined || this.salesorder.sales_time === "") {
        toast.error("请填写  售卖时间!")
        return false
      }
      if (this.salesorder.delivery_time === undefined || this.salesorder.delivery_time === "") {
        toast.error("请填写  配送日期!")
        return false
      }

      if (second(this.salesorder.sales_time)>second(this.salesorder.delivery_time)){
        toast.error("售卖日期必须小于等于配送日期！")
        return false
      }

      let itemData = this.$refs.itemList.tableArray

      for (let i = 0; i < itemData.length; i++) {
        if (itemData[i].product_cost_tax==0&&itemData[i].is_gift==0){
          toast.error(itemData[i].name+"售价为0，请勾选为赠品!")
          return false
        }

        if (itemData[i].qty ==undefined||itemData[i].qty =='') {
          toast.error("销售数量必填!")
          return false
        }
        if (itemData[i].product_cost_tax < 0) {
          toast.error("含税售价必须大于等于0!")
          return false
        }
        if (itemData[i].qty <= 0&&itemData[i].ext.categoryId!=352) {
          toast.error("售卖数量必须大于0!")
          return false
        }
      }


      for (let i = 0; i < this.salesOrderItem.length; i++) {
        saveSalesOrderItem(this.salesOrderItem[i])
      }

      if (confirm("是否提交")) {
        console.log("测试")
        this.salesorder.sales_time = second(this.salesorder.sales_time)
        this.salesorder.delivery_time = second(this.salesorder.delivery_time)
        this.readonly = 1
        store.dispatch('offlinesalesorder/save', this.salesorder).then(res => {
          if (res.data.code == 0) {
            this.salesorder.sales_time = toTime(this.salesorder.sales_time)
            this.salesorder.delivery_time = toTime(this.salesorder.delivery_time)

            store.dispatch('offlinesalesorder/changestatus', {id: this.salesorder.order_id, status: 1}).then(res => {
              if (res.data.code == 0) {
                if (res.data.data.ext != undefined) {
                  toast.error(res.data.data.ext.information)
                } else {
                  toast.success("提交成功")
                  this.$router.push({name: 'apps-offlinesalesorder-list'})
                }
              } else {
                toast.error(res.data.data)
                this.$router.push({name: 'apps-offlinesalesorder-list'})
              }
            })
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const fromChildren = function (val) {
      this.salesOrderItem = val
    }

    const saveSalesOrderItem = function (result) {
      store
          .dispatch('offlinesalesorderitem/updateOrderItem', {
            itemid: result.item_id,
            qty: result.qty,
            productcost: result.product_cost,
            productcosttax: result.product_cost_tax,
            taxrate: result.tax_rate,
            costprice: result.cost_price,
            isgift: result.is_gift,
            memo:result.memo,
          })
          .then(res => {
            // refetchData()
            if (res.data.code == 0) {
              toast.success(result.name + '数据已保存!')
            } else {
              toast.error(res.data.data)
            }

          })
          .catch((e) => {
            toast.error(result.name + "数据保存失败")
          })
    }

    const changeSelect = function (key, event) {
      if (event != null) {
        this.salesorder[key] = event.value
      } else {
        this.salesorder[key] = null
      }

      if (key=='sales_warehouse'){
        const billHeadArray = this.billHeadArray
        billHeadArray.length = 0
        this.billHead = ""
        this.salesorder.bill_head = null
        store.dispatch('offlinesalesorder/delSOItem', {orderId: this.salesorder.order_id}).then(res => {
          this.$refs.itemList.refetchData();
        })

        store.dispatch('warehousehead/getByWarehouseId', {id: this.salesorder.sales_warehouse}).then(res => {
          let data = res.data.data
          if (data.length===1){
            billHeadArray.push({"label": data[0].head_info, "value": data[0].head_id})
            this.salesorder.bill_head = data[0].head_id
            this.billHead = data[0].head_info
          }else {
            for (let i = 0; i < data.length; i++) {
              billHeadArray.push({"label": data[i].head_info, "value": data[i].head_id})
            }
          }
        })

      }

    }

    const getCompanyByCompanyTypeIsTwo = function (d) {
      axios.get('/api/company/list', {
        params: {
          company_type: 2
        }
      })
          .then(function (res) {
            const a = res.data.data.list
            for (let i = 0; i < a.length; i++) {
              d.push({"label": a[i].company_name, "company_id": a[i].company_id})
            }
          })
    }

    const getUser = function (res, userId, r) {
      axios.get('/api/user/edit', {
        params: {
          id: userId
        }
      })
          .then(function (response) {
            r.push({
              "channel_contact_name": response.data.data.full_name,
              "channel_contact_mobile": response.data.data.mobile
            })
          })
          .catch(function (error) {
          })

    }

    const onSelectUser = function (bvModalEvt) {
      let checked = this.$refs.myUserSelect.getSelected();
      this.items = this.items.concat(checked)
      this.salesName = this.items[0].full_name
      this.salesorder.sales_name = this.salesName
      this.salesorder.sales_id = this.items[0].user_id
      this.salesorder.sales_mobile = this.items[0].mobile
      this.items.length = 0
      this.storeArray.length = 0
      // this.channelArray.length = 0
      this.salesWarehouseArray.length = 0
      this.channelName = ""
      this.storeName = ""
      this.salesWarehouse = ""
      this.getStoreAndCompany(this.storeArray, this.channelArray, this.salesorder)
    }

    const setSales = function (event) {
      if (event!=null){
        this.salesName = event.label
        this.salesorder.sales_name = this.salesName
        this.salesorder.sales_id = event.value
        this.salesorder.sales_mobile = event.mobile
        this.$forceUpdate()
      }else {
        this.salesName = ''
        this.salesorder.sales_name = null
        this.salesorder.sales_id = null
        this.salesorder.sales_mobile = null
        this.$forceUpdate()
      }
    }

    const onSelectContract = function (bvModalEvt) {
      let checked = this.$refs.contractSelect.getSelected()[0];
      this.items = this.items.concat(checked)
      this.contractNo = this.items[0].contract_no
      this.salesorder.contract_no = this.contractNo
      this.salesorder.contract_id = this.items[0].contract_id
      this.items.length = 0
    }

    const addExtraCostSubtotal = function () {
      store.dispatch('offlinesalesorderitem/calextracost', {
        orderid: this.salesorder.order_id,
        extracost: this.salesorder.extra_cost_subtotal,
      })
          .then(res => {
            toast.success('success!')
            this.$refs.itemList.refetchData();
          })

    }

    const setStore = function (event) {
      if (event != null) {
        this.salesorder.store_id = event.store_id
        this.salesorder.store_name = event.label
        this.salesorder.store_company_id = event.store_company_id
        //终端客户清空
        this.salesorder.channel_id = null
        this.channelName = null
        this.salesorder.channel_name = null
        this.salesorder.channel_contact_name = null
        this.salesorder.channel_contact_mobile = null
        this.salesorder.channel_contact_id = null
        //仓库清空
        const salesWarehouseArray = this.salesWarehouseArray
        salesWarehouseArray.length = 0
        this.salesorder.sales_warehouse = null
        this.salesWarehouse = ""
        //业务员清空
        const salesArray = this.salesArray
        salesArray.length = 0
        this.salesName = ''
        this.salesorder.sales_name = null
        this.salesorder.sales_id = null
        this.salesorder.sales_mobile = null
        //抬头清空
        this.billHeadArray.length=0
        this.billHead = ""
        this.salesorder.bill_head = null
        store.dispatch('warehouse/findWareHouseIdsByStoreIds', {storeId: this.salesorder.store_id})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                salesWarehouseArray.push({"label": data[i].warehouse_name, "value": data[i].warehouse_id})
              }
            })
        store.dispatch('storesales/getUserByStoreId', {storeId: this.salesorder.store_id,type:1})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                salesArray.push({"label": data[i].full_name, "value": data[i].user_id,"mobile":data[i].mobile})
              }
            })
      } else {
        this.salesorder.store_id = null
        this.salesorder.store_name = null
        this.salesorder.store_company_id = null
      }
    }

    const setChannelName = function (event) {
      if (event != null) {
        this.salesorder.channel_id = event.company_id
        this.salesorder.channel_name = event.label
        const salesorder = this.salesorder
        let _this = this
        store.dispatch('contact/getlinker', {cid: this.salesorder.channel_id})
            .then(function (response) {
              let data = response.data.data
              salesorder.channel_contact_name = data.length>0?data[0].name:null
              salesorder.channel_contact_mobile = data.length>0?data[0].mobile:null
              salesorder.channel_contact_id = data.length>0?data[0].contact_id:null
              _this.$forceUpdate()
            })
      } else {
        this.salesorder.channel_id = null
        this.salesorder.channel_name = null
        this.salesorder.channel_contact_name = null
        this.salesorder.channel_contact_mobile = null
        this.salesorder.channel_contact_id = null
      }
    }

    const fromChildrenDB = function (checked, modal) {
      if (modal == 'modal-select-user') {
        this.salesName = checked.full_name
        this.salesorder.sales_name = this.salesName
        this.salesorder.sales_id = checked.user_id
        this.salesorder.sales_mobile = checked.mobile
        this.storeArray.length = 0
        // this.channelArray.length = 0
        this.channelName = ""
        this.storeName = ""
        getStoreAndCompany(this.storeArray, this.channelArray, this.salesorder.sales_id)

      }
      if (modal == 'modal-select-contract') {
        this.contractNo = checked.contract_no
        this.salesorder.contract_no = this.contractNo
        this.salesorder.contract_id = checked.contract_id
      }
      this.$bvModal.hide(modal)
    }

    const onUploaded = function (id, attachment, result) {
      this.salesorder[id] = result
    }

    const getStoreAndCompany = function (storeArray, channelArray, salesOrder) {
      salesOrder.store_id = null
      salesOrder.store_name = null
      salesOrder.store_company_id = null
      salesOrder.channel_id = null
      salesOrder.channel_name = null
      salesOrder.sales_warehouse = null
      salesOrder.channel_contact_name = null
      salesOrder.channel_contact_mobile = null
      salesOrder.channel_contact_id = null
      store.dispatch('storesales/getStoreByUserId', {userId: salesOrder.sales_id, type: 1})
          .then(function (response) {
            let data = response.data.data
            for (let i = 0; i < data.length; i++) {
              storeArray.push({
                "label": data[i].store_name,
                "store_id": data[i].store_id,
                "store_company_id": data[i].company_id
              })
            }
          })
      // store.dispatch('storesales/getStoreByUserId', {userId: salesOrder.sales_id, type: 2})
      //     .then(function (response) {
      //       let data = response.data.data
      //       for (let i = 0; i < data.length; i++) {
      //         channelArray.push({"label": data[i].company_name, "company_id": data[i].company_id})
      //       }
      //     })
      this.$forceUpdate()
    }

    const getCompany = function (channelArray) {
      store.dispatch('company/searchOffSalesChannelCustomer')
          .then(function (response) {
            let data = response.data.data.list
            for (let i = 0; i < data.length; i++) {
              channelArray.push({"label": data[i].company_name+ (data[i].first_letter != undefined?  data[i].first_letter.length >=4? "(" + data[i].first_letter.substr(0,4) + ")":"(" +data[i].first_letter+ ")" : ""), "company_id": data[i].company_id})
            }
          })
    }

    const openSelect = function (VueSelect) {
      return VueSelect.search.length >=2 && VueSelect.open
    }

    //团队名称：位置到“业务员”上面，根据登陆人账号匹配所属团队，若只存在一个团队，则自动带出；若存在多个，需要手动选择，若登陆人找不到关联的团队，则开放全部团队供选择；
    const getStoreByUser = function (_this) {
      const salesWarehouseArray = _this.salesWarehouseArray
      const salesArray = _this.salesArray
      const billHeadArray = _this.billHeadArray
      if (_this.id===0){
        store.dispatch('storesales/getStoreByUserId', {userId: _this.user.level == 3 ?_this.user.user_id : null, type: 1})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                _this.storeArray.push({
                  "label": data[i].store_name,
                  "store_id": data[i].store_id,
                  "store_company_id": data[i].company_id
                })
              }
              if (data.length===1){
                _this.salesorder.store_id = _this.storeArray[0].store_id
                _this.salesorder.store_name = _this.storeArray[0].label
                _this.salesorder.store_company_id = _this.storeArray[0].store_company_id
                _this.storeName = _this.storeArray[0].label
                // //仓库清空
                salesWarehouseArray.length = 0
                _this.salesorder.sales_warehouse = null
                _this.salesWarehouse = ""
                // //业务员清空
                salesArray.length = 0
                _this.salesName = ''
                _this.salesorder.sales_name = null
                _this.salesorder.sales_id = null
                _this.salesorder.sales_mobile = null
                //仓库赋值
                store.dispatch('warehouse/findWareHouseIdsByStoreIds', {storeId: _this.salesorder.store_id})
                    .then(function (response) {
                      let data = response.data.data
                      for (let i = 0; i < data.length; i++) {
                        salesWarehouseArray.push({"label": data[i].warehouse_name, "value": data[i].warehouse_id})
                      }
                    })
                //业务员赋值
                store.dispatch('storesales/getUserByStoreId', {storeId: _this.salesorder.store_id,type:1})
                    .then(function (response) {
                      let data = response.data.data
                      for (let i = 0; i < data.length; i++) {
                        salesArray.push({"label": data[i].full_name, "value": data[i].user_id,"mobile":data[i].mobile})
                      }
                    })
              }

            })
      }else {
        store.dispatch('storesales/getStoreByUserId', {userId: _this.user.level == 3 ?_this.user.user_id : null, type: 1})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                _this.storeArray.push({
                  "label": data[i].store_name,
                  "store_id": data[i].store_id,
                  "store_company_id": data[i].company_id
                })
              }
            })
        //仓库赋值
        store.dispatch('warehouse/findWareHouseIdsByStoreIds', {storeId: _this.salesorder.store_id})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                salesWarehouseArray.push({"label": data[i].warehouse_name, "value": data[i].warehouse_id})
              }
            })
        //业务员赋值
        store.dispatch('storesales/getUserByStoreId', {storeId: _this.salesorder.store_id,type:1})
            .then(function (response) {
              let data = response.data.data
              for (let i = 0; i < data.length; i++) {
                salesArray.push({"label": data[i].full_name, "value": data[i].user_id,"mobile":data[i].mobile})
              }
            })
        //抬头赋值
        store.dispatch('warehousehead/getByWarehouseId', {id: _this.salesorder.sales_warehouse}).then(res => {
          let data = res.data.data
          for (let i = 0; i < data.length; i++) {
            billHeadArray.push({"label": data[i].head_info, "value": data[i].head_id})
          }
        })
      }
    }

    const saveAndSubmitAndPrint = function () {
      if (this.salesorder.type_id === undefined || this.salesorder.type_id === "") {
        toast.error("请选择 业务类型!")
        return false
      }
      if (this.salesorder.channel_name === undefined || this.salesorder.channel_name === ""||this.salesorder.channel_name == null) {
        toast.error("请选择 渠道方!")
        return false
      }
      if (this.salesorder.trade_type === undefined || this.salesorder.trade_type === "") {
        toast.error("请选择  贸易方式!")
        return false
      }
      if (this.salesorder.company_name === undefined || this.salesorder.company_name === "") {
        toast.error("请选择  主体名称!")
        return false
      }
      if (this.salesorder.sales_id === undefined || this.salesorder.sales_id === ""||this.salesorder.sales_id == null) {
        toast.error("请选择  业务员!")
        return false
      }
      if (this.salesorder.contract_type === undefined || this.salesorder.contract_type === "") {
        toast.error("请选择  合同类型!")
        return false
      }
      if (this.salesorder.invoice_type === undefined || this.salesorder.invoice_type === "") {
        toast.error("请选择  发票类型!")
        return false
      }
      /*if (this.salesorder.settlement_method === undefined || this.salesorder.settlement_method === "") {
        toast.error("请选择  结算方式!")
        return false
      }
      if (this.salesorder.delivery_type === undefined || this.salesorder.delivery_type === "") {
        toast.error("请选择  物流方式!")
        return false
      }
      if (this.salesorder.payment_method === undefined || this.salesorder.payment_method === "") {
        toast.error("请选择  付款方式!")
        return false
      }*/
      if (this.salesorder.store_id === undefined || this.salesorder.store_id === ""||this.salesorder.store_id == null) {
        toast.error("请选择  店铺!")
        return false
      }
      if (this.salesorder.sales_warehouse === undefined || this.salesorder.sales_warehouse === ""||this.salesorder.sales_warehouse == null) {
        toast.error("请选择  仓库!")
        return false
      }
      if (this.salesorder.sales_time === undefined || this.salesorder.sales_time === "") {
        toast.error("请填写  售卖时间!")
        return false
      }
      if (this.salesorder.delivery_time === undefined || this.salesorder.delivery_time === "") {
        toast.error("请填写  配送日期!")
        return false
      }

      let itemData = this.$refs.itemList.tableArray

      for (let i = 0; i < itemData.length; i++) {
        if (itemData[i].product_cost_tax==0&&itemData[i].is_gift==0){
          toast.error(itemData[i].name+"售价为0，请勾选为赠品!")
          return false
        }

        if (itemData[i].qty ==undefined||itemData[i].qty =='') {
          toast.error("销售数量必填!")
          return false
        }
        if (itemData[i].product_cost_tax < 0) {
          toast.error("含税售价必须大于等于0!")
          return false
        }
        if (itemData[i].qty <= 0&&itemData[i].ext.categoryId!=352) {
          toast.error("售卖数量必须大于0!")
          return false
        }
      }


      for (let i = 0; i < this.salesOrderItem.length; i++) {
        saveSalesOrderItem(this.salesOrderItem[i])
      }

      if (confirm("是否提交")) {
        this.salesorder.sales_time = second(this.salesorder.sales_time)
        this.salesorder.delivery_time = second(this.salesorder.delivery_time)
        this.readonly = 1
        store.dispatch('offlinesalesorder/save', this.salesorder).then(res => {
          if (res.data.code == 0) {
            toast.success('数据已保存!')
            this.salesorder.sales_time = toTime(this.salesorder.sales_time)
            this.salesorder.delivery_time = toTime(this.salesorder.delivery_time)

            store.dispatch('offlinesalesorder/changestatus', {id: this.salesorder.order_id, status: 1}).then(res => {
              if (res.data.code == 0) {
                if (res.data.data.ext != undefined) {
                  toast.error(res.data.data.ext.information)
                } else {
                  toast.success("提交成功")


                  store.dispatch('lodop/createSalesOrderLODOP', {
                    is: true,
                    ids: this.salesorder.order_id,
                    warehouseId:this.salesorder.sales_warehouse,
                  }).then(res => {
                    if (res.data.code === 0) {
                      const data = res.data.data
                      var LODOP = getLodop();
                      if (LODOP==undefined){
                        toast.error("打印插件未安装，请安装插件!")
                        return ;
                      }
                      LODOP.PRINT_INIT('打印送货单') //打印初始化
                      //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
                      LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
                      // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
                      if (data[0].ext.page==2){
                        LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
                      }
                      if (data[0].ext.page==3){
                        LODOP.SET_PRINT_PAGESIZE(1, 2200, 930, '') //设定纸张大小
                      }
                      LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
                      var html = ''
                      for (let i = 0; i < data.length; i++) {
                        var item =  ``
                        var items = data[i].ext.item
                        for (let j = 0; j < items.length; j++) {
                          item+=` <tr height="23px">
                        <td align="center">${items[j].ext.no}</td>
                        <td align="left" style="font-size:12px;white-space: nowrap;">${items[j].name}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].ext.code}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].product_cost_tax}/${items[j].ext.product_unit}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].qty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].ext.price}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${items[j].memo}</td>
                    </tr>`
                        }

                        html = `
                <table width="740px" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">&nbsp;</td>
                    </tr>
                    <tr height="30px">
                        <td colspan="3" align="center"><font face="楷体" size="4">${data[i].store_name==null?'':data[i].store_name}送货单(${data[i].ext.printTime}次)</font></td>
                    </tr>
                    <tr height="25px">
                        <td width="250">客户:${data[i].channel_name}</td>
                        <td width="240">客户电话:${data[i].ext.contact==null?'':data[i].ext.contact}</td>
                        <td width="250">销售编号:${data[i].order_no}</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">地址:${data[i].ext.address==null?'':data[i].ext.address}</td>
                        <td width="240">制单人:${data[i].ext.user}</td>
                        <td width="250">打印日期:${data[i].ext.time}</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">仓库:${data[i].ext.warehouse.warehouse_name}</td>
                        <td width="240">地址:${data[i].ext.warehouse.location==null?'':data[i].ext.warehouse.location}(${data[i].ext.warehouse.mobile})</td>
                        <td width="250">抬头:${data[i].ext.warehouseHead==null?'':data[i].ext.warehouseHead}</td>
                    </tr>
                    <tr height="25px">
                        <td width="250">业务员：${data[i].sales_name}${data[i].sales_mobile==null?'':data[i].sales_mobile}</td>
                        <td width="240">司机：</td>
                        <td width="250">收货人：</td>
                    </tr>
                </table>
                <table width="740px" border="1" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td width="40px" align="center">序号</td>
                        <td width="300px" align="center">商品名称</td>
                        <td width="170px" align="center">69码</td>
                        <td width="50px" align="center">单价</td>
                        <td width="50px" align="center">数量</td>
                        <td width="70px" align="center">金额</td>
                        <td width="60px" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="left" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">合计</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data[i].ext.allQtySheet}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data[i].ext.allPrice}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                    </tr>
                </table>
          `
                        lodopWatermark(LODOP)
                        LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, '<body >' + html + '</body>') // 增加超文本项（打印内容即为body内容）
                        if (data[i].ext.page==2){
                          if (data[i].ext.item.length>14){
                            LODOP.NewPage();
                            const page = parseInt((data[i].ext.item.length-14) / 21)
                            for (let j = 0; j < page; j++) {
                              LODOP.NewPage();
                            }
                          }
                        }
                        if(data[i].ext.page==3){
                          if (data[i].ext.item.length>6){
                            LODOP.NewPage();
                            const page = parseInt((data[i].ext.item.length-6) / 14)
                            for (let j = 0; j < page; j++) {
                              LODOP.NewPage();
                            }
                          }
                        }
                        // LODOP.SET_PRINT_STYLEA(i+1,"AngleOfPageInside",-90);
                        LODOP.NewPage();

                      }
                      // LODOP.ADD_PRINT_HTM(88, 20, 1500, 1500, '<body>' + html + '</body>')
                      LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
                      // LODOP.PRINT()
                      this.$router.push({name: 'apps-offlinesalesorder-edit'})
                      location.reload()
                    }else {
                      toast.error(res.data.data)
                    }
                  })
                }
              } else {
                toast.error(res.data.data)
              }
            })
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    return {
      edit,
      view,
      cancel,
      save,
      toTime,
      toDate,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      changeSelect,
      second,

      getCompanyByCompanyTypeIsTwo,
      getUser,
      onSelectUser,
      onSelectContract,
      addExtraCostSubtotal,
      fromChildren,
      saveSalesOrderItem,
      setStore,
      fromChildrenDB,
      onUploaded,
      setChannelName,
      getStoreAndCompany,
      saveAndSubmit,

      getCompany,
      getStoreByUser,
      setSales,
      saveAndSubmitAndPrint,
      openSelect,
    }
  },
  created() {
    this.oneSwitch = getCodeLabel("setting","one_switch")
    this.id = this.$route.query.id || 0;
    this.readonly = this.$route.params.readonly || null;
    if (this.$route.params.readonly === 0 || this.id === 0) {
      this.readonly = 0
    }
    const userData = getUserData()
    this.user = userData

    this.edit()

    // this.getCompany(this.channelArray)
  },
  watch: {
    storeName:{
      handler(newValue,oldValue){
          this.channelArray = []
          store.dispatch('company/search',{status:4,state:1,type:6,store_id:isEmpty(this.salesorder.store_id)?0:this.salesorder.store_id})
              .then(response => {
                let data = response.data.data.list
                for (let i = 0; i < data.length; i++) {
                  this.channelArray.push({"label": data[i].company_name+ (data[i].first_letter != undefined?  data[i].first_letter.length >=4? "(" + data[i].first_letter.substr(0,4) + ")":"(" +data[i].first_letter+ ")" : ""), "company_id": data[i].company_id})
                }
              })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
